<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.assetSn" class="input" placeholder="资产码" clearable></el-input>
      <el-button type="primary" @click="currentChange(1)" :loading="loading">查询</el-button>
      <el-button type="primary" @click="showAdd">添加</el-button>
    </el-row>
    <el-table :data="infoList" border style="width: 100%" stripe>
      <el-table-column align="center" prop="assetSn" label="资产码"></el-table-column>
      <el-table-column align="center" prop="productionDateCode" label="日期代码"></el-table-column>
      <el-table-column align="center" prop="productionDate" label="日期"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人" width="80px"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间" width="150px"></el-table-column>
      <el-table-column align="center" prop="updateUserName" label="修改人" width="80px"></el-table-column>
      <el-table-column align="center" prop="updateTime" label="修改时间" width="150px"></el-table-column>
      <el-table-column align="center" label="操作" width="150px">
        <template slot-scope="scope">
          <el-button @click="showUpdate(scope.row)" size="mini">编辑</el-button>
          <el-button @click="deleteInfo(scope.row)" type="danger" size="mini">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" width="700px" :before-close="handleClose">
      <el-form v-model="formData" label-width="200px">
        <el-form-item label="资产码：">
          <el-input v-model.trim="formData.assetSn" placeholder="请输入资产码" :readonly="operate!='add'" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="日期：">
          <el-date-picker type="date" placeholder="请输入日期" v-model="formData.productionDate" @blur="toDateCode" style="width: 170px" value-format="yyyy-MM-dd"></el-date-picker>
          <span v-if="formData.dateCode!=''" class="tip">对应代码：{{ formData.dateCode }}</span>
        </el-form-item>
        <el-form-item label="日期代码：">
          <el-input v-model.trim="formData.productionDateCode" @blur="parseDate" maxlength="3" placeholder="请输入3位日期代码" style="width: 170px" clearable></el-input>
          <span v-if="formData.parseDate!=''" class="tip">对应日期：{{ formData.parseDate }}</span>
        </el-form-item>
      </el-form>
      <div class="tip">
        提示：<br/>
        1、日期代码：第1位年：0-9 ； 第2位月：1-9+A,B,C ； 第3位日：1-9+A-V<br/>
        2、输入日期时，会自动转换为日期代码，输入日期代码时自动转换为日期。<br/>
        3、日期代码第3位解析的天数大于当月最后一天时，强制取当月最后一天，并自动调整日期代码。
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="addOrUpdate">确定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  name: "MaterialProductionInfo",
  data() {
    return {
      queryInfo: {
        assetSn: '',
        pageNo: 1
      },
      formData: {
        assetSn: '',
        productionDateCode: '',
        productionDate: '',
        parseDate: '',
        dateCode: ''
      },
      total: 0,
      pageSize: 0,
      infoList: [],
      dialogVisible: false,
      loading: false,
      operate: 'add'
    }
  },
  computed: {
    dialogTitle() {
      return this.operate == 'add' ? '添加日期设置' : '修改日期设置';
    }
  },
  created() {
    this.currentChange(1)
  },
  methods: {
    async search() {
      this.loading = true;
      const {data: res} = await this.$axios.post('materialProductionInfo/queryPage', this.queryInfo);
      this.loading = false;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.infoList = res.data.records;
      this.total = res.data.total;
      this.pageSize = res.data.size;
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    deleteInfo(row) {
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('materialProductionInfo/delete', row.assetSn).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.search();
        })
      })
    },
    parseDate() {
      if (!this.formData.productionDateCode) {
        this.formData.parseDate = ''
        return;
      }
      this.formData.productionDateCode = this.formData.productionDateCode.toUpperCase();
      this.$axios.post('materialProductionInfo/parseDate', this.formData.productionDateCode).then(response => {
        if (response.data.code !== 0) {
          return this.$message.error(response.data.message);
        }
        this.formData.parseDate = response.data.data || '';
        if (this.formData.productionDate != this.formData.parseDate) {
          this.formData.productionDate = this.formData.parseDate;
          this.toDateCode();
        }
      })

    },
    toDateCode() {
      if (!this.formData.productionDate) {
        this.formData.dateCode = ''
        return;
      }
      this.$axios.post('materialProductionInfo/toDateCode', this.formData.productionDate).then(response => {
        if (response.data.code !== 0) {
          return this.$message.error(response.data.message);
        }
        this.formData.dateCode = response.data.data || '';
        if (this.formData.productionDateCode != this.formData.dateCode) {
          this.formData.productionDateCode = this.formData.dateCode;
          this.parseDate()
        }
      })
    },
    showUpdate(row) {
      this.resetFormData()
      this.operate = 'update'
      this.formData.assetSn = row.assetSn
      this.formData.productionDate = row.productionDate
      this.formData.productionDateCode = row.productionDateCode
      this.dialogVisible = true
    },
    showAdd() {
      this.operate = 'add'
      this.resetFormData()
      this.dialogVisible = true
    },
    async addOrUpdate() {
      if (!this.formData.assetSn) {
        return this.$message.error('资产码不能为空');
      }
      if (!this.formData.productionDate && !this.formData.productionDateCode) {
        return this.$message.error('日期和日期代码不能全为空');
      }

      if (this.formData.productionDateCode && this.formData.dateCode && this.formData.productionDateCode != this.formData.dateCode
          || this.formData.productionDate && this.formData.parseDate && this.formData.productionDate != this.formData.parseDate) {
        return this.$message.error('日期和日期代码对应日期不一致');
      }

      let url = this.operate == 'add' ? 'materialProductionInfo/add' : 'materialProductionInfo/update'
      const {data: res} = await this.$axios.post(url, this.formData);
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.currentChange(1)
      this.handleClose()
      this.resetFormData()

    },
    resetFormData() {
      this.formData.assetSn = ''
      this.formData.productionDate = ''
      this.formData.productionDateCode = ''
      this.formData.parseDate = ''
      this.formData.dateCode = ''
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
}
</script>

<style scoped>
.input {
  width: 300px;
  margin-right: 10px;
}

.tip {
  color: orange;
  font-size: 12px;
  padding-left: 20px;
}
</style>